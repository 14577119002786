@import-normalize;
@font-face {
  font-family: 'Mariupol';
  src: url('./fonts/Mariupol-Regular.otf') format('otf');
  font-weight: 400;
  font-style: normal;
}
@keyframes scaleUp {
  from {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}
body {
  margin: 0;
  font-family: Mariupol, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070703;
  background-size: cover;
  background-repeat: no-repeat;
}

// TODO - uncomment below
img {
  display: block;
  height: auto;
  max-width: 100%;
}

.book-wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.story-book {
  position: relative;
}
.page-cover__image::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;

  background-size: 100% 100%;
}

//page
.page {
  background: #070703;
  color: #070703;

  &.--left {
    border: none;
    box-shadow: inset -10px 0 10px -7px rgba(0, 0, 0, 0.4);
  }

  &.--right {
    border-left: 0;
    box-shadow: inset 30px 0 15px -20px rgba(0, 0, 0, 0.4);
  }

  &.hard {
    background-color: hsl(35, 50, 90);
  }
}

// ================== editing here ==================
// .page-content {
//   padding-left: 16px;
//   padding-right: 16px;
// }

// .testOnImg {
//   height: 100%;
// }

.testOnVid {
  height: 100%;
  width: 100%;
}

.videoContainer {
  padding-left: 16px;
  padding-right: 16px;
}

.pageContainer {
  padding-left: 16px;
  padding-right: 16px;
}
// ================== editing here ==================

.story-title {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 400;
  text-align: center;
}
.page-image {
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
}

.page-text {
  margin-top: 16px;
  max-width: 256px;
  margin-left: auto;
  margin-right: auto;

  flex-grow: 1;
  font-size: 12px;
  line-height: 1.3;
}
.page-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  font-size: 80%;
}

.page-icon {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-order {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

// buttons
.buttons-click {
  display: flex;
  margin-top: 16px;
  z-index: 1;
  align-items: baseline;
}

.story-btn {
  display: flex;
  cursor: pointer;
  padding: 0;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
}

.buttons-click button:first-child {
  margin-right: 28px;
}

.volume-btn {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  right: 10%;
  bottom: 5%;
}
.start-button {
  position: fixed;
  right: -10%;
}

// HERE IS THE MOBILE FUCKERY
@media screen and (min-width: 360px) {
  // .book-wrapper {
  //   // padding-bottom: 117px;
  // }

  // .page-image,
  // .page-text {
  //   // max-width: 280px;
  //   // padding: ;
  // }
  // .story-btn {
  //   position: fixed;
  //   bottom: 1rem; // Tailwind's bottom-4 is equivalent to 1rem
  //   left: 50%;
  //   transform: translateX(-50%);
  // }

  .buttons-click {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (min-width: 412px) {
  // .book-wrapper {
  //   padding-bottom: 135px;
  // }
  // .book-wrapper.close {
  //   flex-direction: row;
  // }

  // .start-button {
  //   position: static;
  // }
}

.btns-active {
  display: none;
}

@media screen and (min-width: 577px) {
  body {
    overflow: hidden;
  }

  .btns-active {
    display: block;
  }

  .story-book.active::before {
    content: '';
    position: absolute;
    top: -2%;
    left: -2%;
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    z-index: -1;
    // background-image: url(./images/back-book.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    animation: scaleUp 0.3s ease;
  }

  // .buttons-click {
  //   // margin-top: 4rem;
  //   position: fixed;
  //   bottom: 45%;
  //   left: 0%;
  //   transform: translateX(-50%);
  //   transform: translateY(-50%);
  //   width: 100%;
  //   display: flex;
  //   justify-content: space-between;
  //   // padding-left: 10px;
  //   // padding-right: 0;
  // }

  // .buttons-click {
  //   margin-top: 40px;
  // }

  .main-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 720px) {
  .book-wrapper {
    padding-bottom: 0;
  }
  .page-image,
  .page-text {
    max-width: 302px;
  }

  // .buttons-click {
  //   position: static;
  //   display: flex;
  //   margin-top: 16px;
  //   z-index: 1;
  //   align-items: baseline;
  //   transform: translateX(0%);
  //   margin-top: 40px;
  // }

  // .start-button {
  //   position: absolute;
  //   right: -10%;
  // }
}
@media screen and (min-width: 770px) {
  .story-book.active::before {
    left: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
  }
  // .page-image,
  // .page-text {
  //   max-width: 352px;
  // }
}
@media screen and (min-width: 825px) {
  .page-image,
  .page-text {
    max-width: 402px;
  }
}

@media screen and (min-width: 1379px) {
  .book-wrapper {
    padding-top: 30px;
    padding-left: 0;
    padding-right: 0;
  }
}
